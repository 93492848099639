import { useCallback, useMemo, useState } from 'react';

export const useRotateElements = <T>(items: T[]) => {
  const [item, setItem] = useState<T | undefined>(Array.isArray(items) && items.length > 0 ? items[0] : undefined);

  const setInitialIndex = useCallback(
    (index: number) => {
      if (items.length > 0 && items[index] !== undefined) {
        setItem(items[index]);
      }
    },
    [items],
  );

  const next = useCallback(() => {
    if (!Array.isArray(items) || items.length === 0) {
      return;
    }
    setItem((prev) => {
      const index = prev === undefined ? 1 : items.indexOf(prev);
      return items[index + 1] ?? items[0];
    });
  }, [items]);

  return useMemo(
    () => ({
      item,
      next,
      setInitialIndex,
    }),
    [item, next, setInitialIndex],
  );
};
