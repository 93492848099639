import { SettingKey } from '@/settings/settings';

export interface SettingsStorage {
  get(key: SettingKey): Promise<string | null>;

  set(key: SettingKey, value: string): Promise<void>;
}

export const createInMemorySettingsStorage = (): SettingsStorage => {
  const storage = new Map<SettingKey, string>();
  return {
    get: (key) => Promise.resolve(storage.get(key) ?? null),
    set: (key, value) => {
      storage.set(key, value);
      return Promise.resolve();
    },
  };
};

const storageKey = (key: SettingKey) => `settings:${key}`;

export const createBrowserStorageSettingsStorage = (storage: Storage): SettingsStorage => {
  return {
    get: (key) => Promise.resolve(storage.getItem(storageKey(key))),
    set: (key, value) => {
      storage.setItem(storageKey(key), value);
      return Promise.resolve();
    },
  };
};
