import '@/styles/globals.css';
import { EmotionCache } from '@emotion/react';
import type { AppProps } from 'next/app';
import createEmotionCache from '@/theme/createEmotionCache';
import Head from 'next/head';
import { AppContext } from '@/components/AppContext';

export interface AppPropsWithEmotionCache extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

const App = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: AppPropsWithEmotionCache) => (
  <AppContext emotionCache={emotionCache}>
    <Head>
      <title>Eventjet Ticket-Scanner</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
    </Head>
    <div className="contentContainer">
      <Component {...pageProps} />
    </div>
  </AppContext>
);

export default App;
