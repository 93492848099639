import { ApolloError } from '@apollo/client';
import { InvalidScanCodeError } from '@/customErrors/invalidScanCode';

const errorMessageInvalidCodeInclude = 'not a valid scan code';

export type Result<T, E> = { ok: true; data: T } | { ok: false; error: E };

export const ok = <T>(data: T): Result<T, never> => ({ ok: true, data });
export const err = <E>(error: E): Result<never, E> => ({ ok: false, error });

/**
 * this method should be as generic as possible.
 * - Without async-await the "inner" exception of the passed function was not caught.
 * - Handling of the various errors (e.g. ApolloError) is added here, since it is the earliest possible central place to handle them.
 * Maybe we will come up with a better idea later.
 */
export const exceptionToErrorResult = async <T>(fn: () => T) => {
  try {
    return await fn();
  } catch (error) {
    if (error instanceof ApolloError) {
      if (error.message.includes(errorMessageInvalidCodeInclude)) {
        return err(new InvalidScanCodeError());
      }
      return err(error);
    }
    // TODO we could add different error types here (eg Networkerror, TimeoutError, etc)
    return err(error instanceof Error ? error : new Error('Unknown error'));
  }
};
