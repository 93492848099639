import en from '@/i18n/locales/en';
export * from '@/i18n/locale';

// minimal implementation for now, let's see if it's enough
// TODO userLocale has to be fetched

// Setting this to de-DE for now, until we implement a sophisticated solution
// for handling both client side and server side translations at the same time
// export const userLocale = (): string => 'de-DE'

type English = typeof en;
type EnglishKey = keyof English;

export const translate = <T extends EnglishKey>(groupKey: T, translationKey: keyof English[T]): string => {
  if (en[groupKey] === undefined || en[groupKey]?.[translationKey] === undefined) {
    return translationKey.toUpperCase();
  }

  return en[groupKey]?.[translationKey] ?? translationKey.toUpperCase();
};
