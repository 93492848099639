import { setConfig } from 'next/config';
import { z } from 'zod';

const DEFAULT_TICKET_SYNC_INTERVAL_IN_MS = 40_000;
const DEFAULT_UPLOAD_TICKET_CHECKINS_INTERVAL_IN_MS = 30_000;
const DEFAULT_AUTO_HIDE_CHECKIN_RESULT_IN_MS = 1_500;
const DEFAULT_CAMERA_FACING_MODE = 'user';
const MAX_SCAN_CODE_LENGTH = 12;
const RESET_ERROR_MESSAGE_TIMEOUT_IN_MS = 5_000;
const MAX_CHECKIN_HISTORY_ENTRIES = 10;

export const clientConfig = Object.freeze({
  autoHideCheckinResultTimeout: z
    .number()
    .int()
    .positive()
    .default(DEFAULT_AUTO_HIDE_CHECKIN_RESULT_IN_MS)
    .parse(
      process.env.NEXT_PUBLIC_DEFAULT_AUTO_HIDE_RESULT_IN_MS === undefined
        ? undefined
        : parseInt(process.env.NEXT_PUBLIC_DEFAULT_AUTO_HIDE_RESULT_IN_MS),
    ),
  cameraFacingMode: z
    .string()
    .min(1)
    .default(DEFAULT_CAMERA_FACING_MODE)
    .parse(
      process.env.NEXT_PUBLIC_DEFAULT_CAMERA_FACING_MODE === undefined
        ? undefined
        : process.env.NEXT_PUBLIC_DEFAULT_CAMERA_FACING_MODE,
    ),
  checkinHistory: {
    maxEntries: MAX_CHECKIN_HISTORY_ENTRIES,
  },
  graphql: {
    endpoint: z.string().min(1).parse(process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT),
  },
  maxScanCodeLength: MAX_SCAN_CODE_LENGTH,
  resetErrorMessageTimeout: RESET_ERROR_MESSAGE_TIMEOUT_IN_MS,
  rollbarConfig: {
    accessToken: z.string().min(1).parse(process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN),
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NEXT_PUBLIC_ROLLBAR_ENABLED === 'true',
    payload: {
      environment: z.string().min(1).parse(process.env.NEXT_PUBLIC_ROLLBAR_ENVIRONMENT),
      client: {
        javascript: {
          codeVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
          guess_uncaught_frames: true,
          source_map_enabled: true,
        },
      },
    },
  },
  syncTicketsIntervalDefault: z
    .number()
    .int()
    .positive()
    .default(DEFAULT_TICKET_SYNC_INTERVAL_IN_MS)
    .parse(
      process.env.NEXT_PUBLIC_DEFAULT_SNYC_TICKETS_INTERVAL_IN_MS === undefined
        ? undefined
        : parseInt(process.env.NEXT_PUBLIC_DEFAULT_SNYC_TICKETS_INTERVAL_IN_MS),
    ),
  uploadTicketCheckinsIntervalDefault: z
    .number()
    .int()
    .positive()
    .default(DEFAULT_UPLOAD_TICKET_CHECKINS_INTERVAL_IN_MS)
    .parse(
      process.env.NEXT_PUBLIC_DEFAULT_UPLOAD_TICKET_CHECKINS_INTERVAL_IN_MS === undefined
        ? undefined
        : parseInt(process.env.NEXT_PUBLIC_DEFAULT_UPLOAD_TICKET_CHECKINS_INTERVAL_IN_MS),
    ),
});

setConfig({ clientConfig });
