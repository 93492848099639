import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, createContext, useContext } from 'react';
import { z } from 'zod';
import { SettingKey, Settings, settings as s } from './settings';

const ctx = createContext<Settings | undefined>(undefined);

export const SettingsProvider = ({ settings, children }: PropsWithChildren<{ settings: Settings }>) => (
  <ctx.Provider value={settings}>{children}</ctx.Provider>
);

export const useSetting = <K extends SettingKey>(
  key: K,
): [z.infer<(typeof s)[K]['validate']>, (value: z.infer<(typeof s)[K]['validate']>) => void] => {
  const settings = useContext(ctx);
  if (settings === undefined) {
    throw new Error('useSetting must be used within a SettingsProvider');
  }
  const queryClient = useQueryClient();
  const queryKey = ['setting', key];
  const { data: value } = useQuery({ queryKey, queryFn: () => settings.get(key) });
  const { mutate: change } = useMutation({
    mutationFn: (value: z.infer<(typeof s)[K]['validate']>) => settings.set(key, value),
    mutationKey: ['change-setting', key],
    onError: (err, newValue, context) => {
      queryClient.setQueryData(queryKey, (context as { previous: unknown }).previous);
    },
    onMutate: async (value) => {
      await queryClient.cancelQueries({ queryKey: queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, value);
      return { previous };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKey });
    },
  });
  return [value ?? s[key].default, change];
};
