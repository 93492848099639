import { ReactNode, createContext, useEffect, useState } from 'react';

interface Props {
  children: ReactNode;
}

const initialContext = {
  isOnline: true,
};

export const NetworkStatusContext = createContext(initialContext);

export const NetworkStatusProvider = ({ children }: Props) => {
  // just check initial network status via navigator.onLine to show the correct netwokrStatus eg on offline reload of the page
  const [isOnline, setIsOnline] = useState<boolean>(typeof window === 'undefined' ? true : window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return <NetworkStatusContext.Provider value={{ isOnline }}>{children}</NetworkStatusContext.Provider>;
};
