import { LocalEventStorage } from '@/persistence/localeEventStorage';
import { CameraDevice } from 'html5-qrcode';
import { useMemo } from 'react';
import { useRotateElements } from '@/hooks/useRotateElements';

export const useSelectedVideoDevice = (videoDevices: CameraDevice[]) => {
  const { item, next, setInitialIndex } = useRotateElements(videoDevices);
  const lastUsedDeviceId = LocalEventStorage.getItem('LAST_USED_SCAN_DEVICE');

  const selectedDevice = useMemo(() => {
    if (item === undefined) {
      if (videoDevices.length > 0) {
        const index = findDeviceIndex(videoDevices, lastUsedDeviceId);
        if (index > -1) {
          setInitialIndex(index);
        } else {
          setInitialIndex(0);
          LocalEventStorage.removeItem('LAST_USED_SCAN_DEVICE');
        }
      }
    } else {
      LocalEventStorage.setItem('LAST_USED_SCAN_DEVICE', item.id);
      return item.id;
    }
  }, [item, lastUsedDeviceId, setInitialIndex, videoDevices]);

  return { selectedDevice, next };
};

const findDeviceIndex = (devices: CameraDevice[], deviceId: string) => {
  return devices.findIndex((device) => device.id === deviceId);
};
