import { clientConfig } from '@/config/client.config';
import { LocalEventStorage } from '@/persistence/localeEventStorage';
import { getStringFromQuery } from '@/utils/strings';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  children: ReactNode;
}

interface AppStateProvider {
  eventName: string | undefined;
  scanCode: string | undefined;
  setEventName: (name: string | undefined) => void;
  syncInterval: number;
  updateSyncInterval: (interval: number) => void;
  hideTicketCount: boolean;
  setHideTicketCount: (hideTicketCount: boolean) => void;
  showBlockingErrorPage: boolean;
  setShowBlockingErrorPage: (showBlockingErrorPage: boolean) => void;
}

const initialContext: AppStateProvider = {
  eventName: undefined,
  hideTicketCount: false,
  scanCode: undefined,
  setEventName: () => {
    // noops
  },
  setHideTicketCount: () => {
    // noops
  },
  setShowBlockingErrorPage: () => {
    // noops
  },
  showBlockingErrorPage: false,
  syncInterval: clientConfig.syncTicketsIntervalDefault,
  updateSyncInterval: () => {
    // noops
  },
};

export const AppStateContext = createContext(initialContext);

export const AppStateProvider = ({ children }: Props) => {
  const router = useRouter();
  const [syncInterval, setSyncInterval] = useState<number>(clientConfig.syncTicketsIntervalDefault);
  const [eventName, setEventName] = useState<string>();
  const [hideTicketCount, setHideTicketCount] = useState<boolean>(false);
  const [showBlockingErrorPage, setShowBlockingErrorPage] = useState(false);

  const scanCode = useMemo(() => {
    if (router.query.scanCode === undefined) {
      return undefined;
    }
    return getStringFromQuery(router.query.scanCode);
  }, [router.query.scanCode]);

  const updateSyncInterval = useCallback((interval: number) => {
    LocalEventStorage.setItem('LAST_USED_SYNC_INTERVAL', interval.toString());
    setSyncInterval(interval);
  }, []);

  useEffect(() => {
    const value = parseInt(LocalEventStorage.getItem('LAST_USED_SYNC_INTERVAL'));
    if (Number.isInteger(value)) {
      setSyncInterval(value);
    } else {
      updateSyncInterval(clientConfig.syncTicketsIntervalDefault);
    }
  }, [syncInterval, updateSyncInterval]);

  const contextValue = useMemo(
    () => ({
      eventName,
      hideTicketCount,
      scanCode,
      setEventName,
      setHideTicketCount,
      setShowBlockingErrorPage,
      showBlockingErrorPage,
      syncInterval,
      updateSyncInterval,
    }),
    [
      eventName,
      scanCode,
      syncInterval,
      updateSyncInterval,
      hideTicketCount,
      setHideTicketCount,
      showBlockingErrorPage,
      setShowBlockingErrorPage,
    ],
  );

  return <AppStateContext.Provider value={contextValue}>{children}</AppStateContext.Provider>;
};
