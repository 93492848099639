import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@/theme/theme';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { clientConfig } from '@/config/client.config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppStateProvider } from '@/context/AppStateContext';
import { NetworkStatusProvider } from '@/context/NetworkStatusContext';
import { CameraDataProvider } from '@/context/CameraDataProvider';
import { ScanCodeErrorProvider } from '@/context/ScanCodeErrorContext';
import {
  createBrowserStorageSettingsStorage,
  createInMemorySettingsStorage,
  createSettings,
  SettingsProvider,
} from '@/settings';
import { IntlProvider } from 'react-intl';
import { useLocale } from '@/i18n';
import en from '@/messages/en.json';
import de from '@/messages/de.json';
import { ReactNode } from 'react';

const MESSAGES = { en, de };
const queryClient = new QueryClient();

interface AppContextProps {
  emotionCache: EmotionCache;
  children?: ReactNode;
}

export const AppContext = ({ emotionCache, children }: AppContextProps) => {
  const locale = useLocale();
  const messages = MESSAGES[(locale in MESSAGES ? locale : 'en') as keyof typeof MESSAGES];
  const settingsStorage =
    typeof window === 'undefined'
      ? createInMemorySettingsStorage()
      : createBrowserStorageSettingsStorage(window.localStorage);
  const settings = createSettings(settingsStorage);
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider config={clientConfig.rollbarConfig}>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <AppStateProvider>
                <NetworkStatusProvider>
                  <CameraDataProvider>
                    <ScanCodeErrorProvider>
                      <SettingsProvider settings={settings}>
                        <IntlProvider locale={locale} messages={messages}>
                          {children}
                        </IntlProvider>
                      </SettingsProvider>
                    </ScanCodeErrorProvider>
                  </CameraDataProvider>
                </NetworkStatusProvider>
              </AppStateProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  );
};
