import { ErrorType } from '@/types/error';
import { ReactNode, createContext, useMemo, useState } from 'react';

interface Props {
  children: ReactNode;
}

type ScanCodeError = {
  scanCode: string;
  type: ErrorType;
};

interface ScanCodeErrorProvider {
  clearScanCodeError: () => void;
  setScanCodeError: (scanCode: string, type: ErrorType) => void;
  scanCodeError: ScanCodeError | undefined;
}

export const initialContext: ScanCodeErrorProvider = {
  clearScanCodeError: () => {
    // noops
  },
  scanCodeError: undefined,
  setScanCodeError: () => {
    // noops
  },
};
export const ScanCodeErrorContext = createContext(initialContext);

export const ScanCodeErrorProvider = ({ children }: Props) => {
  const [scanCodeError, setError] = useState<ScanCodeError>();

  const contextValue = useMemo(
    () => ({
      clearScanCodeError: () => {
        setError(undefined);
      },
      scanCodeError,
      setScanCodeError: (scanCode: string, type: ErrorType) => {
        setError({ scanCode, type });
      },
    }),
    [scanCodeError],
  );

  return <ScanCodeErrorContext.Provider value={contextValue}>{children}</ScanCodeErrorContext.Provider>;
};
