type Translations = Record<string, Record<string, string>>;

const TRANSLATIONS: Translations = {
  common: {
    back: 'back',
    loading: 'Loading...',
    noDeviceFound: 'No camera device found',
    originalCheckin: 'original CheckIn',
    resetButtonTitle: 'Reset app',
    ticketId: 'Ticket-ID',
    tickets: 'Tickets',
  },
  // !! needed keys has to match with ErrorTypes enum values in src/enums/error.ts
  errorTypes: {
    invalidResponse: 'Invalid response',
    ticketAlreadyCheckedIn: 'Ticket already checked in',
    ticketInvalidCode: 'Invalid Ticket Code',
    ticketNotFound: 'Ticket not found',
    ticketRevoked: 'Ticket revoked',
    unknown: 'unknown error',
  },
  fatalError: {
    button: 'back to Login',
    headline: 'Oops!',
    imgAltText: '"sad looking, broken little robot"',
    info: 'Our Team is already informed.',
    subline: 'Sorry, this should not happen.',
  },
  reset: {
    additionalInfo: 'Checkins are pushed to the server every ',
    cancelButton: 'wait for upload',
    confirmButton: 'yes, reset data',
    description:
      'There are unpushed CheckIns - do you really want to reset the app? Unpushed checkins will be lost if you reset the app now.',
    resetError: "Couldn't reset data. Please try again later.",
    resetSuccess: 'Data reset successful - reloading page',
    title: 'Reset Data',
    unpushedCheckins: 'unpushed CheckIns',
  },
  scanCode: {
    invalidScanCode: 'Invalid scan code',
    scanCodeInputPlaceholder: 'Please enter your scan code',
  },
  scanTicket: {
    inputLabel: 'Ticket Code',
    invalidScanCode: 'Invalid ticket code',
    loading: 'Loading Tickets...',
    loadingEventData: 'Loading EventData...',
    noTicketsAvailable: 'Currently no tickets available.',
    noTicketsHint: 'Please create tickets in the EventBackend first.',
    submitButton: 'check in',
    success: 'Ticket checked in',
  },
};

export default TRANSLATIONS;
