import { Result, ok } from '@/result';
import { EventData, EventDataUpdate } from '@/types/localStorage';
import { isClient } from '@/utils/helper';
import { getParsedEventDataObject } from '@/utils/parser';

enum LocalEventStorageKeys {
  LAST_USED_SCAN_CODE = 'last-used-scan-code',
  LAST_USED_SCAN_DEVICE = 'last-used-scan-device',
  LAST_USED_SYNC_INTERVAL = 'last-used-sync-interval-in-ms',
}

export class LocalEventStorage {
  static setItem(key: keyof typeof LocalEventStorageKeys, value: string) {
    if (isClient) {
      localStorage.setItem(LocalEventStorageKeys[key], value);
    }
  }

  static getItem(key: keyof typeof LocalEventStorageKeys) {
    if (!isClient) {
      return '';
    }
    const item = localStorage.getItem(LocalEventStorageKeys[key]);
    return item === null ? '' : item;
  }

  static removeItem(key: keyof typeof LocalEventStorageKeys) {
    if (isClient) {
      localStorage.removeItem(LocalEventStorageKeys[key]);
    }
  }

  static getInfosByScanCode = (scanCode: string): Result<EventData | undefined, Error> => {
    if (!isClient) {
      return ok(undefined);
    }
    const item = localStorage.getItem(scanCode);
    if (item === null) {
      return ok(undefined);
    }

    const eventData = getParsedEventDataObject(item);

    if (!eventData.ok) {
      return eventData;
    }

    return ok(eventData.data);
  };

  static getEventNameByScanCode = (scanCode: string): Result<string | undefined, Error> => {
    if (!isClient) {
      return ok(undefined);
    }
    const item = localStorage.getItem(scanCode);

    if (item === null) {
      return ok(undefined);
    }

    const eventData = getParsedEventDataObject(item);
    if (!eventData.ok) {
      return eventData;
    }

    return ok(eventData.data.eventName);
  };

  static getHideTicketCountInfoByScanCode = (scanCode: string): Result<boolean, Error> => {
    if (!isClient) {
      return ok(false);
    }
    const item = localStorage.getItem(scanCode);
    const eventData = getParsedEventDataObject(item);

    if (!eventData.ok) {
      return eventData;
    }

    return ok(eventData.data.hideTargetQuantity);
  };

  static updateInfosByScanCode(scanCode: string, update: EventDataUpdate): Result<undefined, Error> {
    if (!isClient) {
      return ok(undefined);
    }

    const infos = this.getInfosByScanCode(scanCode);

    if (!infos.ok) {
      return infos;
    }

    const updatedInfos = {
      ...(infos.data ?? { eventName: '', hideTargetQuantity: false, lastDownload: null }),
      ...update,
    };

    localStorage.setItem(scanCode, JSON.stringify(updatedInfos));

    return ok(undefined);
  }
}
