import { Html5Qrcode } from 'html5-qrcode';
import { useCallback, useMemo } from 'react';

export const useVideoDevices = () => {
  const getVideoDevices = useCallback(async () => {
    const videoDevices = await Html5Qrcode.getCameras();

    if (videoDevices.length > 0) {
      return videoDevices;
    }
    return [];
  }, []);

  return useMemo(() => ({ getVideoDevices }), [getVideoDevices]);
};
