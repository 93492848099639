import { useCameraSupport } from '@/hooks/useCameraSupport';
import { useSelectedVideoDevice } from '@/hooks/useSelectedVideoDevice';
import { useVideoDevices } from '@/hooks/useVideoDevices';
import { CameraDevice } from 'html5-qrcode';
import { ReactNode, createContext, useMemo, useState } from 'react';

interface Props {
  children: ReactNode;
}

interface CameraDataProvider {
  cameraSupported: boolean;
  detectAvailableVideoDevices: () => void;
  rotateDevices: () => void;
  selectedDevice: string | undefined;
  showNoDevicesFoundInfo: boolean;
  videoDevices: CameraDevice[];
}

const initialContext: CameraDataProvider = {
  cameraSupported: false,
  detectAvailableVideoDevices: () => {
    // noops
  },
  rotateDevices: () => {
    // noops
  },
  selectedDevice: undefined,
  showNoDevicesFoundInfo: false,
  videoDevices: [],
};

export const CameraDataContext = createContext(initialContext);

export const CameraDataProvider = ({ children }: Props) => {
  const [videoDevices, setVideoDevices] = useState<CameraDevice[]>([]);
  const [showNoDevicesFoundInfo, setShowNoDevicesFoundInfo] = useState(false);
  const cameraSupported = useCameraSupport();
  const { getVideoDevices } = useVideoDevices();
  const { selectedDevice, next } = useSelectedVideoDevice(videoDevices);

  const contextValue = useMemo(
    () => ({
      cameraSupported,
      detectAvailableVideoDevices: () => {
        if (videoDevices.length > 0) {
          return;
        }
        getVideoDevices().then((devices) => {
          if (devices.length === 0) {
            setShowNoDevicesFoundInfo(true);
            return;
          }
          setVideoDevices(devices);
        });
      },
      rotateDevices: () => {
        next();
      },
      selectedDevice,
      showNoDevicesFoundInfo,
      videoDevices,
    }),
    [cameraSupported, getVideoDevices, showNoDevicesFoundInfo, next, selectedDevice, videoDevices],
  );

  return <CameraDataContext.Provider value={contextValue}>{children}</CameraDataContext.Provider>;
};
