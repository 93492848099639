import { checkIfCamerasSupported } from '@/utils/camera';
import { useEffect, useState } from 'react';

export const useCameraSupport = () => {
  const [cameraSupported, setCameraSupported] = useState(false);

  useEffect(() => {
    if (typeof navigator.mediaDevices === 'undefined') {
      return;
    }
    const checkSupport = () => {
      checkIfCamerasSupported().then(setCameraSupported);
    };
    checkSupport();
    navigator.mediaDevices.addEventListener('devicechange', checkSupport);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', checkSupport);
    };
  }, []);
  return cameraSupported;
};
