import { Result, err, ok } from '@/result';
import { EventData } from '@/types/localStorage';
import { z } from 'zod';

const eventDataObject = z.object({
  eventName: z.string(),
  hideTargetQuantity: z.boolean(),
  lastDownload: z.number().nullable(),
});

export const getParsedEventDataObject = (jsonString: string | null): Result<EventData, Error> => {
  if (jsonString === null) {
    return err(new Error('jsonString is null'));
  }

  const eventData = parseJson(jsonString);

  if (!eventData.ok) {
    return err(eventData.error);
  }

  const parsedData = eventDataObject.safeParse(eventData.data);
  if (!parsedData.success) {
    return err(parsedData.error);
  }

  return ok(parsedData.data);
};

const parseJson = (jsonString: string): Result<ReturnType<typeof JSON.parse>, Error> => {
  try {
    return ok(JSON.parse(jsonString));
  } catch (e) {
    return err(
      e instanceof Error ? e : new Error(`Something other than an Error was thrown by JSON.parse: ${jsonString}`),
    );
  }
};
