import { SettingsStorage } from '@/settings/storage';
import { z, ZodType } from 'zod';

interface Setting<V extends string = string> {
  default: V;
  validate: ZodType<V>;
}

export const settings = {
  'input-mode': {
    default: 'text-field',
    validate: z.union([z.literal('text-field'), z.literal('camera'), z.literal('hardware')]),
  } satisfies Setting<'text-field' | 'camera' | 'hardware'>,
} as const;

export type SettingKey = keyof typeof settings;

export interface Settings {
  get<K extends SettingKey>(key: K): Promise<z.infer<(typeof settings)[K]['validate']>>;

  set<K extends SettingKey>(key: SettingKey, value: z.infer<(typeof settings)[K]['validate']>): Promise<void>;
}

export const createSettings = (storage: SettingsStorage): Settings => ({
  get: async (key) => {
    const storedValue = await storage.get(key);
    const result = settings[key].validate.safeParse(storedValue);
    if (!result.success) {
      const defaultValue = settings[key].default;
      await storage.set(key, defaultValue);
      return defaultValue;
    }
    return result.data;
  },
  set: async (key, value) => {
    const result = settings[key].validate.safeParse(value);
    if (!result.success) {
      throw new Error(`Invalid value ${value} for setting ${key}`);
    }
    await storage.set(key, value);
  },
});
