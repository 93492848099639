// secure that we always have a string as return value
export const getStringFromQuery = (query: string | string[] | undefined): string | undefined => {
  if (query === undefined) {
    return undefined;
  }

  return Array.isArray(query) && query.length > 0
    ? query[0] === undefined
      ? undefined
      : query[0].toString()
    : query.toString();
};
