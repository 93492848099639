export const ErrorTypes = {
  INVALID_RESPONSE: 'invalidResponse',
  INVALID_SCAN_CODE: 'InvalidScanCode',
  NETWORK_ERROR: 'NetworkError',
  SCAN_CODE_NO_OFFLINE_DATA_FOUND: 'NoOfflineDataFoundForScanCode',
  TICKET_ALREADY_CHECKEDIN: 'ticketAlreadyCheckedIn',
  TICKET_INVALID_CODE: 'tickeInvalidCode',
  TICKET_NOT_FOUND: 'ticketNotFound',
  TICKET_NOT_VALID_AT_TIME: 'ticketNotValidAtTime',
  TICKET_REVOKED: 'ticketRevoked',
} as const;

export type ErrorType = (typeof ErrorTypes)[keyof typeof ErrorTypes];

export enum ServerResponseCheckinErrorMessages {
  TICKET_INVALID_CODE = 'invalid-code',
  TICKET_ALREADY_CHECKEDIN = 'already-checked-in',
  TICKET_REVOKED = 'ticket-revoked',
}
